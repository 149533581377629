import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon } from '../icons/icon.component';
import { View } from '../view/view.component';
import { Btn } from "../buttons/btn.component";

import './app-footer.scss';

export const AppFooter = () => {

  const location : any = useLocation();

  const [fb, setFB] = useState(false);
  const [yb, setYB] = useState(false);
  const [inst, setInst] = useState(false);
  const [li, setLI] = useState(false);
  const [tw, setTW] = useState(false);
  const [visible, setVisible] = React.useState(false)

  const history = useHistory();

  useEffect(() => {
    if(localStorage.getItem("cookies_approved")) {
      setVisible(JSON.parse(localStorage.getItem("cookies_approved") as string));
    } 
}, []);

  return (
    <footer className="AppFooter">
      { !visible &&
        <>
        
        <View className="CookiesBar">             
        <p>هذا الموقع يستخدم ملفات تعريف الارتباط والكوكيز، بإمكانك قراءة <span onClick={()=> window.open(`/privacy`, "_blank")}> سياسة الخصوصية و</span><span onClick={()=> window.open(`/terms`, "_blank")}>شروط الاستخدام</span>. لتفعيل هذه الخاصية اضغط هنا <Btn title="موافق" 
                onClick={() => {
                  setVisible(!visible)
                  localStorage.setItem("cookies_approved","true")                  
                }
                  }/></p>
        
        </View>
        </>      
        }
      <Container >        
        <View>
          <View className="AppFooterContent">
              <div className="Logo" onClick={() => {history.push("/homepage")}}>
                  <Icon name="iconGroupLogo" />
              </div>
              <View className="staticPages">
              <p style={location.pathname.split("/")[1] === "brief" ? {color: "var(--white)"} : {}} onClick={() => history.push("/brief")}>عن موسوعة</p>
              <p style={location.pathname.split("/")[1] === "faq" ? {color: "var(--white)"} : {}} onClick={() => history.push("/faq")}>الأسئلة الأكثر شيوعاً</p>
                 {/* <p style={location.pathname.split("/")[1] === "help" ? {color: "var(--white)"} : {}} onClick={() => history.push("/help")}>المساعدة</p> */}
                {/* <p style={location.pathname.split("/")[1] === "community" ? {color: "var(--white)"} : {}} onClick={() => history.push("/community")}>المجتمعات</p> */}
                {/* <p style={location.pathname.split("/")[1] === "certified" ? {color: "var(--white)"} : {}} onClick={() => history.push("/certified")}>معتمدون لدى</p> */}
                 <p style={location.pathname.split("/")[1] === "partners" ? {color: "var(--white)"} : {}} onClick={() => history.push("/partners")}>الشركاء</p>
                 {/*<p style={location.pathname.split("/")[1] === "blog" ? {color: "var(--white)"} : {}} onClick={() => history.push("/blog")}>المدونة</p>*/}
                 <p style={location.pathname.split("/")[1] === "careers" ? {color: "var(--white)"} : {}} onClick={() => history.push("/careers")}>الوظائف</p>
                <p style={location.pathname.split("/")[1] === "privacy" ? {color: "var(--white)"} : {}} onClick={() => history.push("/privacy")}>سياسة الخصوصية</p>
                <p style={location.pathname.split("/")[1] === "terms" ? {color: "var(--white)"} : {}} onClick={() => history.push("/terms")}>شروط الاستخدام</p>
                <p style={location.pathname.split("/")[1] === "rules" ? {color: "var(--white)"} : {}} onClick={() => history.push("/rules")}>شروط إنتاج المحتوى</p>
                <p style={location.pathname.split("/")[1] === "share" ? {color: "var(--white)"} : {}} onClick={() => history.push("/share")}>التواصل</p>
              </View>
          </View>
          <View className="Flex">
              <View className="smallText">حقوق التأليف والنشر ٢٠٢٥ © بواسطة موسوعة. كل الحقوق محفوظة.</View>                      
              <View className="Icons">
                <View onMouseEnter={() => {
                    setYB(true)
                  }} 
                  onMouseLeave={() => {
                    setYB(false)
                  }}>
                      <Icon name={ yb ? "iconYoutubeWhite"  : "iconYoutubeGray"} onClick={() => window.open("https://www.youtube.com/channel/UC2WLwrNgOft-zgVeJsDG2eQ", "_blank")} />
                  </View>
                  <View onMouseEnter={() => {
                    setInst(true)
                  }} 
                  onMouseLeave={() => {
                    setInst(false)
                  }}>
                      <Icon name={inst ? "iconInstagram" : "iconInstagramGray"} onClick={() => window.open("https://www.instagram.com/mawsuah/", "_blank")}/>
                  </View>
                  <View onMouseEnter={() => {
                    setFB(true)
                  }} 
                  onMouseLeave={() => {
                    setFB(false)
                  }}>
                      <Icon name={fb ? "iconFacebook" : "iconFacebookGray"} onClick={() => window.open("https://www.facebook.com/mawsuah", "_blank")}/>
                  </View>
                  <View onMouseEnter={() => {
                    setLI(true)
                  }} 
                  onMouseLeave={() => {
                    setLI(false)
                  }}>
                      <Icon name={li ? "iconLinkedInWhite" : "iconLinkedinGray"} onClick={() => window.open("https://www.linkedin.com/company/mawsuah", "_blank")}/>
                  </View>
                  <View onMouseEnter={() => {
                    setTW(true)
                  }} 
                  onMouseLeave={() => {
                    setTW(false)
                  }}>
                      <Icon name={tw ? "iconTwitter" : "iconTwitterGray"} onClick={() => window.open("https://twitter.com/mawsuah", "_blank")}/>
                  </View>
              </View>
          </View>
        </View> 
      </Container>
    </footer>
  )
}
