import React, { FunctionComponent, useEffect, useState } from 'react';
import { AppFooter } from '../../../components/app-footer/app-footer.component';
import { AppHeader } from '../../../components/app-header/app-header.component';
import { Icon } from '../../../components/icons/icon.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { View } from '../../../components/view/view.component';
// import rect300 from "../../../assets/images/AboutPage/Rectangle300.png";
// import rect301 from "../../../assets/images/AboutPage/Rectangle301.png";
// import rect302 from "../../../assets/images/AboutPage/Rectangle302.png";
// import rect303 from "../../../assets/images/AboutPage/Rectangle303.png";
// import rect304 from "../../../assets/images/AboutPage/Rectangle304.png";
// import rect305 from "../../../assets/images/AboutPage/Rectangle305.png";
// import rect400 from "../../../assets/images/AboutPage/Rectangle400.png";
// import rect405 from "../../../assets/images/AboutPage/Rectangle405.png";
// import arrowleft from "../../../assets/images/AboutPage/arrowleft.png";
// import arrowright from "../../../assets/images/AboutPage/arrowright.png";
// import linkedInBlack from "../../../assets/images/linkedinBlack.svg";
// import Slider from "react-slick";
import './about.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';


// function SampleNextArrow(props : any) {
//     const { style, onClick } = props;
//     return (
//       <div
//         className="ArrowLeft"
//         style={{ ...style, display: "flex", width: "24px", zIndex: "20",height : "485px"}}
//         onClick={onClick}
//       ><img  src={arrowleft} alt="arrowleft"/></div>
//     );
//   }
  
//   function SamplePrevArrow(props : any) {
//     const { style, onClick } = props;
//     return (
//       <div
//         className="ArrowRight"
//         style={{ ...style, display: "flex", width: "24px", zIndex: "20",height : "485px"}}
//         onClick={() => {
//             onClick()}}
//       ><img src={arrowright} alt="arrowright"/></div>
//     );
//   }

export const AboutPage: FunctionComponent = () => { 

    const history = useHistory();

    const [status, setStatus] = useState("first");
    const [color, setColor] = useState("#0D66D0");
    // const [currentSlide, setCurrendSlide] = useState(0);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    // const settings = {
    //     //centerMode: true,
    //     infinite: true,
    //     className: "SlickSlide",
    //     speed: 300,
    //     slidesToShow: 5,
    //     nextArrow: <SamplePrevArrow className="ArrowRight"/>,
    //     prevArrow: <SampleNextArrow className="ArrowLeft"/>,
    //     afterChange: (slide : number) => {
    //         setCurrendSlide(slide)
    //     }
    //   };

      
    return (
        <View className="App">
            <AppHeader isWhite={true}/>
            <View className="AboutPage">
            <View style={{backgroundColor : color}} className="AboutPageTabs">
               <span className={status === "first" ? "ActiveTab" : ""} onClick={() => {
                   setStatus("first");
                   setColor("#0D66D0");
               }}>
               من نحن؟
               </span>
               <span className={status === "second" ? "ActiveTab" : ""} onClick={() => {
                   setStatus("second");
                   setColor("#498C29");
               }}>
               كيف نعمل؟
               </span>
               <span className={status === "third" ? "ActiveTab" : ""} onClick={() => {
                   setStatus("third");
                   setColor("#CE6226");
               }}>
               ميّزات
               </span>
               <span className={status === "fourth" ? "ActiveTab" : ""} onClick={() => {
                   setStatus("fourth");
                   setColor("#0E8A7C");
               }}>
               المكافآت
               </span>
               <span className={status === "fiveth" ? "ActiveTab" : ""} onClick={() => {
                   setStatus("fiveth");
                   setColor("#9A3F74");
               }}>
               دعم
               </span>
            </View>
            {status ==="fiveth" &&
            <>
            <View className="AboutPageHeader">
                <Container>
                    <Row>
                    <Col className="AboutPageHeaderContent">
                        <h1>نجاحنا واحد</h1>
                        <h2>شاركونا دعمكم</h2>
                        <span>من الصعب لأي فكرة بالوجود أن تعانق العالمية إلا بالمساعدة والتعاون الكامل بين جميع أركانها، فاليد الواحدة لا تصدر صوتاً بينما إذا اجتمعت الأيدي معاً سنسمع صوت فرحة الانتصارات، ففي دعمكم لموسوعة سوف نستطيع إنشاء مجتمعات متكاملة من منشئي المحتوى الرقمي لتصبح المعلومة الموثوقة وبكل سهولة موجودة بين أيادي القرّاء العرب وكل باحث حقيقي عنها.</span>
                    </Col>
                    <Col className="AboutPageHeaderImage">
                        <Icon name="iconRewards5"/>
                    </Col>
                    </Row>
                </Container>
            </View>
            
            <Container>
            <View className="AboutPageContentWhite">
                <Container>
                <Row>
                    <Col className="Content">
                        <h3>منشئي المحتوى</h3>
                        <div style={{height: "4px", backgroundColor: "black", width: "141px", marginBottom: "40px"}}></div>
                        <p>المشاركة والانضمام لمجتمع "موسوعة" من الكتّاب وأصحاب الخبرة والمراجعين والمحررين كلٌّ في مجاله من خلال إنشاء حسابات شخصية، والبدء بالعملية الكتابية من أجل التواصل والتفاعل مع مجتمعاتهم ومع القرّاء لإثراء المعرفة العربية الموثوقة.</p>                        
                    </Col>
                    <Col className="image">
                        <Icon name="iconRectangle174"/>
                    </Col>
                </Row>
                </Container>
            </View>
            </Container>
            
            <View className="AboutPageContentColored">
                <Container>
                <Row>
                    <Col>
                    <h3>القرّاء</h3>
                    <div style={{height: "4px", backgroundColor: "black", width: "47px", marginBottom: "40px"}}></div>
                    <p>الدخول إلى موقع "موسوعة" وإنشاء حسابات شخصية، ومن ثم تحديد الاهتمامات والانطلاق إلى عالم القراءة، والتفاعل مع المواد والكتّاب ومشاركة مقالات كتّاب "موسوعة" عبر قنواتهم في وسائل التواصل الاجتماعي وكل شخص باحث عن المعلومة.</p>                    
                    </Col>
                </Row>
                </Container>
            </View>
            
            
            <View className="AboutPageContentWhite">
                <Container>
                <Row>
                <Col className="Content">
                    <h3>المانحون</h3>
                    <div style={{height: "4px", backgroundColor: "black", width: "71px", marginBottom: "40px"}}></div>
                    <p>الإيمان بقيمة وأهمية منصة "موسوعة" في دعم المجتمعات المحلية، وتوفير الدعم المعنوي والمادي لفتح قنوات التواصل والتشبيك مع المجتمع المدني والمؤسسات للنهوض بالمعرفة العربية الموثوقة كونها أداة تطوّر ورقي الشعوب.</p>                    
                </Col>
                <Col className="image">
                    <Icon name="iconRectangle200"/>
                </Col>
                </Row>
                </Container>
            </View>
           
            
            <View className="AboutPageContentColored">
                <Container>
                    <Row>
                    <Col>
                    <h3>المستثمرون</h3>
                    <div style={{height: "4px", backgroundColor: "black", width: "108px", marginBottom: "40px"}}></div>
                    <p>المشاركة في مشروع "موسوعة" وتوفير الخبرات الكبيرة التي يمتلكونها، والدعم المالي لفتح خطوط اتصال والتشبيك لتطبيق الرؤية المستقبلية لموسوعة كأول منصّة عربية قائمة على إنشاء مجتمع عربيّ معرفي رقمي مختص قائم على نظام حوافز معنوي ومادي متكامل عبر الدفع بالعملة الرقمية.</p>
                    <button onClick={() => {history.push("/share")}}>
                    ادعم المعرفة العربية
                    </button>
                    </Col>
                    </Row>
                </Container>
            </View>
            
            </>}
            {status === "first" && 
            <>
            <View style={{backgroundColor : color, paddingBottom: "75px"}} className="AboutPageHeader">
                <Container>
                    <Row>
                    <Col className="AboutPageHeaderContent">
                        <h1>مجتمع معرفي مختّص</h1>
                        <h2>مسيرنا يكشف لنا طريقنا</h2>
                        <span>المحتوى المعرفي باللغة العربية على الشبكة المعلوماتية لا يتجاوز الـ1%، حيث اجتمع مجموعة من الشركاء العرب المتخصصين في القطاعات التقنيّة التسويقية، الإعلامية والبحثيّة، وتشاركوا حلم "موسوعة" لإثراء المحتوى المعرفي وإدارته باحترافية، حيث تمثل "موسوعة" نظام تحفيزي عربي مبني على تقنية البلوكتشين، وهي المنصة العربية المفضلة لمنتجي المحتوى المختصين بهدف إعادة الحضور العربي إلى عالم العلم والمعرفة، فهذا المكان هو أفضل من يحتضن قادة الفكر وروّاد العلوم المختصين وأصحاب المهارات والمبدعين لإيصال محتواهم الرقمي الموثوق إلى أكثر من 500 مليون متحدث بالعربية.</span>
                    </Col>
                    <Col className="AboutPageHeaderImage">
                        <Icon name="iconRewards1"/>
                    </Col>
                    </Row>
                </Container>
            </View>
            
            <Container>
            <View className="AboutPageContentWhite">
                <Container>
                <Row>
                    <Col className="Content">
                        <h3>الانطلاقة</h3>
                        <div style={{height: "4px", backgroundColor: "black", width: "60px", marginBottom: "40px"}}></div>
                        <p>نحن مجموعة من الباحثين والتقنيين والخبراء في مجالات التسويق والبرمجة وإنشاء المحتوى وإدارته، رغبنا مثل أي عربيّ أن نسهّل وصول الجمهور العربيّ إلى المعلومة الموثوقة، وقرّرنا أن نحقق معكم هذا الحلم. حيث أنّ المحتوى المعرفي العربيّ الموثوق لا يتجاوز الـ 1% من المحتوى على الشبكة المعلوماتية، لم يكن سهلاً أن نحلم بما وصلنا إليه. الباحثون العرب يواجهون صعوبات جمّة، وتكثر المغالطات والأخطاء في المحتوى المتوفّر باللغة العربيّة، وفوق ذلك أو بسببه، تفتقد منصّات النشر العربية إلى أنظمة مراجعة وتوثيق وفهرسة، ويعاني الكتّاب والباحثون عند التفكير بتحقيق عائد من النشر العلميّ الرصين.</p>                        
                    </Col>
                    <Col className="image">
                        <Icon name="iconRectangle176"/>
                    </Col>
                </Row>
                </Container>
            </View>
            </Container>
            <View style={{backgroundColor : `rgba(13, 102, 208, 0.12)`}} className="AboutPageContentColored">
                <Container>
                <Row>
                    <Col>
                    <h3>الهدف</h3>
                    <div style={{height: "4px", backgroundColor: "black", width: "50px", marginBottom: "40px"}}></div>
                    <p>كانت الرؤية واضحة: مجتمعات "موسوعة" كمصدر عربيّ موثوق للمعلومة الموثّقة. نهدف منها إلى أن نعيد لأمّتنا العربية حضورها الذي تستحق في عالم العلم والمعرفة، وذلك بجعل القراءة والبحث والكتابة أفعالاً أكثر سلاسة ومتعة، وبمساعدة المختصّين على مساعدة مجتمعهم والمشاركة بإثراء المعرفة الموثوقة لكل الباحثين باللغة العربية من جميع أنحاء العالم.</p>
                    </Col>
                </Row>
                </Container>
            </View>
            <View className="AboutPageContentWhite">
                <Container>
                <Row>
                <Col className="Content">
                    <h3>المستهدفون</h3>
                    <div style={{height: "4px", backgroundColor: "black", width: "96px", marginBottom: "40px"}}></div>
                    <p>تقدّم "موسوعة" من خلال مجتمعها الخاص، محتوى موثوق للقرّاء، الأكاديميين، الناشرين، الحكومات ومحترفي الخدمات المستقلين بصورة مجانية أو مدفوعة، وذلك في مجالات النصوص والصوتيات والمرئيّات والرسومات التوضيحية والبيانات، وكل ما يتعلق بالمحتوى العربيّ بجميع أشكاله، إلى جانب أكاديمية تدريب باللغة العربية لتعليم مهارة كتابة المحتوى العلميّ الرقميّ، مع تقديم فرصة لتحقيق عائد من المساهمة للكتّاب والمراجعين والمحرّرين معنوياً ومادياً.</p>                    
                </Col>
                <Col className="image">
                    <Icon name="iconRectangle177"/>
                </Col>
                </Row>
                </Container>
            </View>
            <View style={{backgroundColor : `rgba(13, 102, 208, 0.12)`, marginBottom: "60px"}} className="AboutPageContentColored">
                <Container>
                <Row>
                    <Col>
                    <h3>التحدي</h3>
                    <div style={{height: "4px", backgroundColor: "black", width: "61px", marginBottom: "40px"}}></div>
                    <p>بدأنا مشوارنا فعليّاً في 2019، وبدأت نواة مجتمع "موسوعة" بالتكوّن. ولأنّنا نعرف الفوائد الكبيرة التي يحقّقها وجود الباحثة أو الباحث في مجتمع متخصّص، حرصنا على أن تتيح "موسوعة" لأعضائها فرصة تأسيس مجتمعات تتشارك الاهتمام أو التخصّص، وتوفّر خاصّية المتابعة والاقتداء، لتكون منصّة يدعم كل جزء منها بقية الأجزاء. </p>
                    <p>صعود تقنية "البلوكتشين" وفّرت لنا القدرة على الحفاظ على حقوق الكتّاب الأدبية والمادّية المتعلّقة بإنتاجهم، "موسوعة" تستخدم أكثر التقنيات كفاءة لتحفظ للكتّاب جهدهم وجهدهنّ، وذلك من خلال توليد المحتوى الرقميّ الذي يمثّل فرصة دخل، وباباً لتحقيق العائد المعنوي من تعريف الجمهور بالكاتب، مع جعله جزءاً من مجتمع مختصّ يحقّق له عوائد معرفيّة، إلى جانب الفائدة المعرفية التي يحققها القارئ. ولم ننسَ المراجعين والمحررين من العوائد وتقديرجهودهم بهدف نشر محتوى معرفي موثوق ومراجع ومحّرر.</p>                    
                    <button onClick={() => {history.push("/share")}}>
                    ادعم المعرفة العربية
                    </button>
                    </Col>
                </Row>
                </Container>
            </View>
         {/*   <View className="AboutPageContentWhite">
                <Container>
                <Row>
                <Col className="Content">
                    <h3>الفريق</h3>
                    <div style={{height: "4px", backgroundColor: "black", width: "96px", marginBottom: "40px"}}></div>
                    <p>مزيج من الخبرات والتخصصات والطموح، اجتمعوا في أشخاص احترافيين في مجالاتهم، وشكّلوا بعملهم الدؤوب طريقاً واحداً لحلمهم.. شاركهم بإثراء المعرفة العربية على "موسوعة"!</p>
                </Col>
                </Row>
                </Container>

            </View>


            <div className="SliderContainer">

                <Slider {...settings}>
                <div className={currentSlide === 6 ? "slick-center" : ""} style={{display: "flex", alignItems: "center"}}>
                    <img src={rect300}alt="photoOwners"/>
                    <span className="title">د. عيسى كواليت</span>
                    <span className="subTitle">شريك مؤسس</span>                    
                    <p>عيسى هو طبيب ورجل أعمال ومستثمر أردني. شارك في تأسيس "موسوعة" عام 2019، وداعم حقيقي للشركات الناشئة المبتكرة، وهو مستثمر فعّال في شركات عربية متنوعة وناجحة على الإنترنت منذ العام 2012.</p>                    
                    <img  src={linkedInBlack} className="GroupIcons" alt="socialIcons" onClick={() => {window.open('https://www.linkedin.com/in/issa-kawalit-952944138/')}}/>
                </div>
                <div className={currentSlide === 7 ? "slick-center" : ""} style={{display: "flex", alignItems: "center"}}>
                    <img src={rect302}alt="photoOwners"/>
                    <span className="title">محمد الرمحي</span>
                    <span className="subTitle">شريك مؤسس - الرئيس التنفيذي للعمليات </span>                    
                    <p>محمد رائد أعمال ومخرج مبدع. شارك في تأسيس "موسوعة" عام 2019، واكتسب خبرة في مجال الإعلان الرقمي، مختص في بناء استراتيجيات علامات تجارية وخلق أفكار ومحتوى إبداعي بخبرة أكثر من 12 عاماً.</p>                    
                    <img src={linkedInBlack} className="GroupIcons" alt="socialIcons" onClick={() => {window.open('https://www.linkedin.com/in/moh-alramahi/')}}/>
                </div>                
                <div className={currentSlide === 0 ? "slick-center" : ""} style={{display: "flex", alignItems: "center"}}>
                    <img src={rect305}alt="photoOwners"/>
                    <span className="title">يمامة جرادات</span>
                    <span className="subTitle">شريك مؤسس - الرئيس التنفيذي</span>                    
                    <p>يمامة رائدة أعمال ومستشارة مختصّة في استراتيجيات الاتصال والمحتوى الرقمي بخبرة أكثر من 12 عاماً. شاركت في تأسيس "موسوعة" عام 2019، واكتسبت خبرة كبيرة في بناء استراتيجيات تجربة المستخدم وعمليات تطوير المنتج.</p>                    
                    <img src={linkedInBlack} className="GroupIcons" alt="socialIcons" onClick={() => {window.open('https://www.linkedin.com/in/yamamajaradat/')}}/>
                </div>                
                <div className={currentSlide === 1 ? "slick-center" : ""} style={{display: "flex", alignItems: "center"}}>
                    <img src={rect303}alt="photoOwners"/>
                    <span className="title">محمد اشبيطة</span>
                    <span className="subTitle">شريك مؤسس - الرئيس التنفيذي للتكنولوجيا</span>                    
                    <p>محمد خبير تكنولوجيا "البلوكتشين"، كان له دوراً فعّالاً في بناء العديد من منصات التجارة الإلكترونية العربية الناجحة بخبرة أكثر من 15 عاماً، بالإضافة إلى عمله المختصّ في قطاع البنوك والعملات الرقميّة.</p>                    
                    <img src={linkedInBlack} className="GroupIcons" alt="socialIcons" onClick={() => {window.open('https://www.linkedin.com/in/eshbeata/')}}/>
                </div>
                <div className={currentSlide === 2 ? "slick-center" : ""} style={{display: "flex", alignItems: "center"}}>
                    <img src={rect304}alt="photoOwners"/>
                    <span className="title">عبدالله الرمحي</span>
                    <span className="subTitle">مدير إنتاج رقمي</span>                    
                    <p>عبدالله مسؤول عن إنتاج الفيديوهات والمحتوى الرقمي بخبرة أكثر من 15 عاماً. عمل مع الكثير من العلامات التجارية والمنصّات العالمية مثل نتفلكس، الجزيرة والميادين.</p>                    
                    <img src={linkedInBlack} className="GroupIcons" alt="socialIcons" onClick={() => {window.open('https://www.linkedin.com/in/abdulla-al-ramahi-75828266/')}}/>
                </div>   
                <div className={currentSlide === 3 ? "slick-center" : ""} style={{display: "flex", alignItems: "center"}}>
                    <img src={rect301} alt="photoOwners"/>
                    <span className="title">علاء دادان</span>
                    <span className="subTitle">مصمّم احترافي</span>                    
                    <p>علاء مستشار مختّص في تصميم تجربة وواجهة المستخدم بخبرة أكثر من 15 عاماً، يعمل باحتراف لتحويل متطلبات المنتج وميّزاته لتصاميم تفاعلية، شارك في بناء العديد من المواقع العالمية والعربية.</p>                    
                    <img src={linkedInBlack} className="GroupIcons" alt="socialIcons" onClick={() => {window.open('https://www.linkedin.com/in/adadan/')}}/>
                </div>
                <div className={currentSlide === 4 ? "slick-center" : ""} style={{display: "flex", alignItems: "center"}}>
                    <img src={rect400}alt="photoOwners"/>
                    <span className="title">جودت شماس</span>
                    <span className="subTitle">مرشد ومستشار</span>                    
                    <p>جودت خبير تسويق رقمي معتمد من شركة جوجل بخبرة أكثر من 15 عاماً. عمل مع الكثير من العلامات التجارية العالمية والعربية لتحسين وتطوير صورتهم الرقميّة.</p>                    
                    <img src={linkedInBlack} className="GroupIcons" alt="socialIcons" onClick={() => {window.open('https://www.linkedin.com/in/jawdatshammas/')}}/>
                </div>
                <div className={currentSlide === 5 ? "slick-center" : ""} style={{display: "flex", alignItems: "center"}}>
                    <img src={rect405}alt="photoOwners"/>
                    <span className="title">مروة بني هذيل</span>
                    <span className="subTitle">مسؤولة المحتوى الإبداعي</span>                    
                    <p>مروة مختصّة في مجال الكتابة الإبداعية باللغة العربية في مجالي الإعلام والإعلان بخبرة أكثر من 12 سنة. عملت مع العديد من العلامات التجارية العربية والعالمية وساهمت في بناء منصّات رقمية لهم.</p>                    
                    <img src={linkedInBlack} className="GroupIcons" alt="socialIcons" onClick={() => {window.open('https://www.linkedin.com/in/marwabanihuthail/')}}/>
                </div>
                </Slider>

            </div> 

            </div>*/}

            {/*<View className="AboutPageContentWhite">
                <Container style={{borderBottom: "1px solid #CACACA", paddingBottom: "80px"}}>
                <Row>
                <Col className="Content">
                    <p>تقدّم موسوعة بمجتمعها المحتوى الموثوق للقراء للأكاديميين والناشرين والحكومات ومحترفي الخدمات المستقلين بصورة مجانية أو والمراجعين والمحرّرين معنويّا ومادّيّامدفوعة، وذلك في مجالات النصوص والصوتيات والمرئيّات والرسومات التوضيحية والبيانات وكل ما يتعلق بالمحتوى العربي بجميع أشكاله، إلى</p>
                </Col>
                </Row>
                </Container>
            </View> 
            */}
            </>}
             {status === "second" && 
            <>
            <View style={{backgroundColor : color, paddingBottom: "75px"}} className="AboutPageHeader">
                <Container>
                    <Row>
                    <Col className="AboutPageHeaderContent">
                        <h1>نكتب معًا .. نقرأ معًا</h1>
                        <h2>متعة الرحلة في الوصول</h2>
                        <span>استندت "موسوعة" في تحقيق أهدافها على هيكلها المكوّن من خمس أركان: الاستكشاف، التواصل، التعاون، التحفيز والتفاعل بين الكتّاب والقرّاء، باستخدام تقنية "البلوكتيشن". حيث تتيح "موسوعة" إمكانيّة تحقيق مكافآت من خلال نقاط وعملات رقميّة عن طريق نظام حوافز مادي ومعنوي متكامل للكتّاب، كما تساعد مجتمعاتها في الاحتكاك الفعّال بمجتمعات معرفيّة متخصّصة. كما تقوم على نظام مراجعة أكاديمي مزدوج الستار " Double Blinded Peer Review"، إذ لا يعرف المجتمع المختّص المسؤول عن الكتابة والمراجعة والتحرير بعضهم بعض خلال عملية إنتاج المحتوى لضمان الشفافية والعدالة. "موسوعة" ستوفّر خدماتها بطريقتين؛ مجانية في المرحلة الأولى، ومدفوعة في المراحل اللاحقة.</span>
                    </Col>
                    <Col className="AboutPageHeaderImage">
                        <Icon name="iconRewards2"/>
                    </Col>
                    </Row>
                </Container>
            </View>
            
            <Container>
            <View className="AboutPageContentWhite">
                <Container>
                <Row>
                    <Col className="Content">
                        <h3>المجتمعات</h3>
                        <div style={{height: "4px", backgroundColor: "black", width: "80px", marginBottom: "40px"}}></div>
                        <p>نعتمد في "موسوعة" على الطرق الراسخة التي أثبتت التجربة الإنسانية فاعليّتها؛ نؤسس مجتمعات للكتابة والقراءة، مما يجعل المعرفة نشاطاً اجتماعيّاً، ومن مبدأ إيماننا بأن النجاح الكبير أساسه المشاركة والتعاون، فقد أتحنا تلك الفرصة، حيث يمكن للكاتب إنشاء حسابه الشخصي للانضمام لمجتمعه المشابه له في التخصّص، وكذلك الأمر للقارئ والمراجع والمحرّر، أيّا كان جنسه أو جنسيته. ومن هنا يمكن وصف "موسوعة" بكونها منصّة تشاركية قبل كل شيء. </p>
                    </Col>
                    <Col className="image">
                        <Icon name="iconRectangle172"/>
                    </Col>
                </Row>
                </Container>
            </View>
            </Container>
            <Container>
            <View style={{marginTop: "60px", marginBottom: "80px"}} className="AboutPageContentWhite">
                <Container>
                <Row>
                    <Col className="Content">
                        <p>قد تكون عضواً في مجتمع "موسوعة" بوصفك قارئاً أو كاتباً أو مراجعاً أو محرّراً، وقد تمزج بين دورين أو أكثر. حيث تحتوي "موسوعة" على ثمة مجتمعات معرفية داخلية تضمّ الاهتمامات المشتركة، أي إنها تقوم بإنشاء مجتمعات مختصة داخل الاختصاص نفسه، وبعد جهدٍ جهيد قمنا باستبعاد المشكلات التي تواجه الكتّاب والقرّاء بالمواقع الأخرى قدر الإمكان، لنشكّل نواة المعلومات الموثوقة عن طريق مراجعة المواد وتدقيقها عبر المجتمعات المعنية للمساعدة بإخراجها للقارئ بأحسن صورة ممكنة.</p>
                    </Col>
                </Row>
                </Container>
            </View>
            </Container>
            <View style={{backgroundColor : `rgba(73, 140, 41, 0.12)`}} className="AboutPageContentColored">
                <Container>
                <Row>
                    <Col>
                    <h3>المراجعة</h3>
                    <div style={{height: "4px", backgroundColor: "black", width: "61px", marginBottom: "40px"}}></div>
                    <p>يولد المحتوى على يد أشخاص لا يعرف أي منهما الآخر، ضماناً للنزاهة العلمية، فالكاتب يكتب ويتقدّم بما كتب إلى المراجعين، فتعيّن خوارزمية "موسوعة" مراجعاً من ذوي الاختصاص لا يعرفه الكاتب، ولا يعرف هو اسم الكاتب، ليقوم بمراجعة المحتوى علميّاً، ويعيد الملاحظات _إذا لزم الأمر_ إلى الكاتب، فيعدّل الكاتب مقالته ويعيدها إلى المراجع، حتى تصل المقالة لحال مرضية للطرفين، ثم تذهب إلى التحرير حيث يقوم محرّر محترف بتحرير المقالة، ثم تذهب إلى النشر. تسمّى هذه الطريقة (المراجعة مزدوجة الستار)، وهي تضمن أعلى معايير الجودة العلمية والمعرفية، وتوثيق الحقوق الأدبية. في "موسوعة" ثمّة عيارات واضحة معلنة للكتابة والمراجعة والتحرير، وتشمل: ذكر المصادر، أصالة المحتوى، طريقة العرض، قواعد الاقتباس، صحة الحقائق، السياق العام، تقسيم المقال والهيكل العام، وضوح اللغة، حقوق الصور والرسومات التوضيحية. يأتي هذا لضمان سلاسة العملية وعدالتها وجودة النتيجة.</p>
                    {/* <SpacerVertical height={"2.250em"}/>
                    <p>تقدّم موسوعة فرصة دخل عن طريق الكتابة العلمية الموثوقة، يقدّم العائد المادّي من خلال نظام توكينز يترجم إلى عملة رقمية في المراحل المتقدمة. ابدأ الكتابة</p> */}
                    <button onClick={() => {history.push("/main_signup")}}>
                    انضم إلى مجتمعك المختّص
                    </button>
                    </Col>
                </Row>
                </Container>
            </View>
            <View className="AboutPageContentWhite">
                <Container>
                <Row>
                <Col className="Content">
                    <h3>التكنولوجيا</h3>
                    <div style={{height: "4px", backgroundColor: "black", width: "83px", marginBottom: "40px"}}></div>
                    <p>يقوم نظام "البلوكتشين" بحفظ الحقوق الأدبية، وما يرافقها من حقوق معنوية ومادّية عند الكتابة أو المراجعة أو التحرير في "موسوعة". يمكن النظر إلى تقنية "البلوكتشين" بوصفها قاعدة بيانات غير مركزية، توفّر خاصيّتين تبدوان متناقضتين؛ هما: </p>
                    <ul>
                        <li>
                        الشفافية، بإظهار اسم صاحب الجهد بوضوح، وإمكانية معرفته كل المعلومات الخاصة بمادته.
                        </li>
                        <li>
                        الخصوصية، بالحفاظ على سريّة معلومات المستخدم. 
                        </li>
                    </ul>
                </Col>
                <Col className="image">
                    <Icon name="iconRectangle205"/>
                </Col>
                </Row>
                </Container>
            </View>
            <Container>
            <View style={{marginTop: "60px", marginBottom: "80px"}} className="AboutPageContentWhite">
                <Container>
                <Row>
                    <Col className="Content">
                        <p>إضافة إلى كون تقنية "البلوكتشين" هي التقنية الأكثر حصانة ضدّ الاختراق والتلاعب، فهي شبكة مكوّنة من مجموعة من القطع المترابطة، تحفظ كل منها معلومة لا يمكن التعديل عليها، لكن يمكن إضافة معلومة جديدة في قطعة جديدة مرتبطة بالسلسلة، ولذلك فهي سهلة التتبّع. هذا يعني أن الناطقين بالعربية من أفراد ومؤسسات ووسائل إعلام ومنشآت تعليمية في قطاعات شتّى سيصلون بسهولة إلى مصدر معرفي رقمي موثوق وشامل يقدّم بصور مختلفة، ويحمل معلومات معرفية موثّقة.</p>
                    </Col>
                </Row>
                </Container>
            </View>
            </Container>
            </>}
             {status === "third" && 
            <>
            <View style={{backgroundColor : color, paddingBottom: "75px"}} className="AboutPageHeader">
                <Container>
                    <Row>
                    <Col className="AboutPageHeaderContent">
                        <h1>يُميّزنا أننا نميّزك</h1>
                        <h2>استمروا بالكتابة، فالمعرفة ميزة التطوّر</h2>
                        <span>وجود المعلومة الموثوقة هو ميزة الآن، مع ذلك ولأننا نطمح ألّا يبقى الأمر هكذا، فنحن نحرص على منح الكتّاب والمراجعين والمحررين قيمتهم، فهم قوام مجتمع "موسوعة". حيث تمنحنا تقنية "البلوكشين" الأكثر أماناً التي تم استخدامها ببناء "موسوعة" على حفظ المواد في أماكن متعددة لحمايتها باستمرار، والقدرة أيضاً على مكافأة جزء من جهود مجتمعنا على المستوى المعنوي والمستوى المادي. ويزوّد موقعنا القرّاء والمجتمعات بتجربة مستخدم مميّزة في وضوحها وبساطتها للوصول للمعلومات بكل سهولة. وبإنشاء حسابات شخصية لجميع المستخدمين للتواصل والتفاعل مع بعضهم البعض أكثر عن قرب.</span>
                    </Col>
                    <Col className="AboutPageHeaderImage">
                        <Icon name="iconRewards3"/>
                    </Col>
                    </Row>
                </Container>
            </View>
            
            <Container>
            <View className="AboutPageContentWhite">
                <Container>
                <Row>
                    <Col className="Content">
                        <h3>منشئو المحتوى</h3>
                        <div style={{height: "4px", backgroundColor: "black", width: "123px", marginBottom: "40px"}}></div>
                        <p>الحماس يجمعنا لتشاركونا كتاباتكم العظيمة وأفكاركم الإبداعية وخبرات السنين، فكثير منّا يمتلك العلم والمعرفة المميّزة في مجال تخصّصه، فمن أهم ميّزات "موسوعة" التي نوفّرها لمنشئي المحتوى هي إنشاء حساب شخصي للتواصل مع الجمهور والانضمام بالوصول إلى المجتمع المختّص. فدعونا نساعدكم في "موسوعة" للوصول إلى أكثر من 500 مليون متحدثاً بالعربية وبأفضل الطرق التسويقية باستخدام أدوات واستراتيجيات منهجية تتمثّل بالسرد القصصي.</p>
                    </Col>
                    <Col className="image">
                        <Icon name="iconRectangle173"/>
                    </Col>
                </Row>
                </Container>
            </View>
            </Container>
            <Container>
            <View style={{marginTop: "60px", marginBottom: "80px"}} className="AboutPageContentWhite">
                <Container>
                <Row>
                    <Col className="Content">
                        <p>إضافة إلى ضمان الحقوق الأدبية للعاملين على كلّ مقالة من كاتب ومراجع ومحرّر، فإنّ نظام "موسوعة" مفتوح على وسائل التواصل الاجتماعي، أي أنّ "موسوعة" تمثّل مدخلاً إلى عالم النشر والوصول إلى جمهور القرّاء والشركات والمؤسسات. كما أن "موسوعة" تقدّم للمستخدمين مدخلاً إلى مصادر معرفية ثرية.</p>
                    </Col>
                </Row>
                </Container>
            </View>
            </Container>
            <View style={{backgroundColor : `rgba(206, 98, 38, 0.12)`}} className="AboutPageContentColored">
                <Container>
                <Row>
                    <Col>
                    <h3>ميّزات إضافية</h3>
                    <div style={{height: "4px", backgroundColor: "black", width: "106px", marginBottom: "40px"}}></div>
                    <p>نساعد على دعم المحتوى الذي ينتجه المجتمع بطرق إبداعية مثل الأشرطة المرئية والملصقات المعلوماتية، وهي تقدّم فرصة لتوثيق الحسابات، والحصول على أوسمة ذات دلالة، والتشبيك مع شبكة واسعة من المحترفين العرب، والتعرّف على مجتمعات متخصّصة، إضافة إلى الترابط بين المنظمات والحكومات والمؤسسات من جهة، والكتاب والمراجعين والمحررين من جهة أخرى، لتفتح فرصاً أكبراً لهم. </p>                    
                    <SpacerVertical height={"2.250em"}/>
                    <p>تقدّم "موسوعة" فرصة دخل عن طريق الكتابة العلمية الموثوقة، من خلال تقديم العائد المادي المتمثّل بنظام العملة الرقميّة في المراحل المتقدمة، ابدأ الكتابة.</p>                    
                    <button onClick={() => {history.push("/main_signup")}}>
                    شارك مجتمعك المختّص
                    </button>
                    </Col>
                </Row>
                </Container>
            </View>
            
            
            <View className="AboutPageContentWhite">
                <Container>
                <Row>
                <Col className="Content">
                    <h3>القرّاء</h3>
                    <div style={{height: "4px", backgroundColor: "black", width: "41px", marginBottom: "40px"}}></div>
                    <p>المعرفة أصل التطوّر والحضارة الإنسانية، لذلك نقدّم طريقة سهلة للوصول إلى معلومة موثوقة، فالقارئ العربي ّمتعطّش لهذا، لكن "موسوعة" لم تكتف بذلك. حيث تقدّم "موسوعة" فرص كثيرة وأولها فرصة لإنشاء حساب شخصي للقاريء مبني على اهتماماته التي اختارها والمعرفة المعيّنة التي يبحث عنها ويريدها حسب اختياره وهذه القائمة قابلة للتغيّر باستمرار حسب رغبته. كما تقدّم "موسوعة" أيضاً فرصة للاحتكاك بالكتّاب والقرّاء، وتكوين مجتمعات معرفية ذات اهتمام مشترك، وتمكّن القارئ من الاقتداء بقرّاء ومتابعة كتّاب بعينهم والتفاعل معهم، وتكوين مسارات معرفيّة مميّزة. </p>                    
                </Col>
                <Col className="image">
                    <Icon name="iconRectangle203"/>
                </Col>
                </Row>
                </Container>
            </View>
           
            
            <View style={{backgroundColor : `rgba(206, 98, 38, 0.12)`}} className="AboutPageContentColored">
                <Container>
                    <Row>
                    <Col>
                    <h3>ميّزات إضافية</h3>
                    <div style={{height: "4px", backgroundColor: "black", width: "108px", marginBottom: "40px"}}></div>
                    <p>تجربة المستخدم في "موسوعة" فريدة من نوعها، إذ تعتمد على تصميمات تفاعلية جاذبة، كما نعمل على تحسين خوارزمية البحث بصورة مستمرّة، ليتمكّن القارئ من الوصول إلى ما يهمّه فعلاً بأقلّ جهد، عن طريق تزويدها بالقدرة على فهم أفكارهم وأحاسيسهم المتضمنة في كلماتهم، ونحرص على تقديم المعلومة في سياقها الذي يجعلها فاعلة وبطريقة إبداعية للاستفادة منها بالتعلّم أو البحث من أجل الاستكشاف، المعرفة وتطوير الذات.</p>                    
                    <button onClick={() => {history.push("/main_signup")}}>
                    ابدأ الاستكشاف
                    </button>
                    </Col>
                    </Row>
                </Container>
            </View>
            
            </>}
             {status === "fourth" && 
             <>
                <View style={{backgroundColor : color, paddingBottom: "125px"}} className="AboutPageHeader">
                    <Container>
                        <Row>
                        <Col className="AboutPageHeaderContent">
                            <h1>اكتب لتجني الأرباح</h1>
                            <h2>نساعدك على تحقيق القيمة الأكبر لموادك</h2>
                            <span>في شركة "موسوعة" الناشئة نحاول وبكل جهد من خلال خططنا المتسقبلية على بناء نظام تحفيزي متكامل متعلق بالمستوى المعنوي والمستوى المادي. حيث نقوم في المستوى المعنوي ببناء الشعارات والتصنيفات لمنشئي المحتوى، وأيضاً بدعم الملف الشخصي والمواد للكتّاب تسويقياً عن طريق الإعلانات الممولة في جميع المنصّات العالمية، وفي الوقت القادم القريب سيكون مادياً عن طريق إنشاء محفظة لكل من منشئي المحتوى على أن يتم إعطاء قيمة رقميّة "التوكنز" لكل من شارك في عملية الكتابة بقيم متفاوته، على أن تتحول "التوكنز" بالمرحلة النهائية إما إلى عملة رقميّة أو نقاط يستطيع كل من منشئي المحتوى صرفها نقداً أو الشراء بها.</span>
                        </Col>
                        <Col className="AboutPageHeaderImage">
                            <Icon name="iconRewards4"/>
                        </Col>
                        </Row>
                    </Container>
                </View>
                <View className="AboutPageContentWhite">
                    <Container>
                    <Row>
                    <Col className="Content">
                        <h3>المكافآت</h3>
                        <div style={{height: "4px", backgroundColor: "black", width: "74px", marginBottom: "40px"}}></div>
                        <p>يمنح نظامنا أعضاء مجتمع "موسوعة" جميعاً إمكانية تقييم أعمال الأعضاء الآخرين. ليس الأمر مقتصراً على تقييم القارئ للمقال، يمكن أيضاً للكتّاب والمراجعين والمحرّرين تقييم عمل بعضهم البعض، ممّا يجعل العمل مع "موسوعة" تنافسيّاً، وفرصة للارتقاء في الأداء. علماً أن ذلك التقييم قائم على الأداء لا على الأشخاص. </p>
                        <p>نحرص في "موسوعة" بأن يحصل الكاتب أو المراجع أو المحرّر على ثمرة جهده معنويّاً ومادّياً، مع حفظ حقّه الطبيعي في ملكيّة الحقوق الأدبية لإنتاجه. ينقسم نظــام المكافآت إلى قسمين: </p>
                    </Col>
                    <Col className="image">
                        <Icon name="iconRectangle204" />
                    </Col>
                    </Row>
                    </Container>
                </View>
                <Container>
                    <Row>
                    <View className="GreenFrame">
                        <View className="GreenFrameImage">
                            <Icon name="iconFrame1"/>
                        </View>
                        <View className="GreenFrameList">
                            <h3>نظام المكافآت المعنوي :</h3>
                            <ul>
                                <li>حصول الكتّاب على التصنيفات، ويتضمّن ذلك نظام الأوسمة</li>
                                <li>تحويل المواد المكتوبة إلى فيديوهات أو مقاطع صوتية</li>
                                <li>التسويق الرقمي للكتّاب ولموادهم بأشكال متطوّرة ومن أهمها السرد القصصي</li>
                            </ul>
                        </View>
                    </View>
                    </Row>
                </Container>
                <SpacerVertical height={"3.571em"}/>
                <Container>
                    <Row>
                    <View className="GreenFrame">
                        <View className="GreenFrameImage">
                            <Icon name="iconFrame2"/>
                        </View>
                        <View className="GreenFrameList">
                            <h3>نظام المكافآت المادي :</h3>
                            <ul>
                            <li>حصول الكتّاب على النقاط    </li>
                                <li>حصول كل مادة على قيمة بالعملة الرقميّة مع إمكانية تحويلها</li>
                                <li>عائد مادي من بيع المواد المكتوبة إلى الشركات والأفراد</li>
                            </ul>
                        </View>
                    </View>
                    </Row>
                </Container>
                <SpacerVertical height={"3.571em"}/>
                <View className="AboutPageContentWhite">
                    <Container>
                    <Row>
                    <Col className="Content">
                        <h3>كيف يعمل نظام المكافآت؟</h3>
                        <div style={{height: "4px", backgroundColor: "black", width: "74px", marginBottom: "40px"}}></div>
                        <p>يهدف برنامج المكافآت إلى تشجيع إنتاج محتوى عربي متخصص وعالي الجودة، مع ضمان تعويض عادل لمنتجي المحتوى. يعتمد النظام على نظام نقاط ذكي مدعوم بتقنية البلوكشين لضمان الشفافية والأمان.                        </p>
                        {/* <Icon name="iconAboutPageReward"  style={{width:"1106px"}} /> */}
                        <h4>طرق كسب النقاط:</h4>
                        <ul>
                            <li> نشر مقالات متخصصة وموثوقة: المقالات التي تخضع للمراجعة تحصل على نقاط أعلى</li>
                            <li> نسب التفاعل: كلما زاد عدد القراءات والمشاركات والتعليقات زادت المكافآت</li>
                            <li>الكتابة في مجالات متخصصة: المحتوى التقني والمطلوب بشدة يحصل على نقاط إضافية</li>
                            <li> المساهمة في المجتمع: مراجعة المقالات والتفاعل مع المنشورات يكافئك أيضًا بالنقاط</li>
                        </ul>
                    </Col>
                    </Row>
                    </Container>
                </View>
                <SpacerVertical height={"1.571em"}/>
                <View className="AboutPageContentWhite">
                    <Container>
                    <Row>
                    <Col className="Content">
                        <h3>كيف تُحسب المكافآت؟</h3>
                        <div style={{height: "4px", backgroundColor: "black", width: "74px", marginBottom: "40px"}}></div>                        
                        {/* <Icon name="iconAboutPageReward"  style={{width:"1106px"}} /> */}
                        <h4>تعتمد الخوارزمية على عدة عوامل تشمل:</h4>
                        <ul>
                            <li>جودة المقال (تمت مراجعته من الخبراء أم لا)</li>
                            <li> مدى التفاعل (عدد القراءات، المشاركات، التعليقات)</li>
                            <li>الانتظام في النشر (المساهمات المستمرة تزيد من النقاط المكتسبة)</li>
                            <li>أهمية الموضوع (المجالات الحيوية تحصل على مكافآت أعلى)                            </li>
                        </ul>
                    </Col>
                    </Row>
                    </Container>
                </View>
                <SpacerVertical height={"1.571em"}/>
                <View className="AboutPageContentWhite">
                    <Container>
                    <Row>
                    <Col className="Content">
                        <h3>كيف تُحسب المكافآت؟</h3>
                        <div style={{height: "4px", backgroundColor: "black", width: "74px", marginBottom: "40px"}}></div>                        
                        {/* <Icon name="iconAboutPageReward"  style={{width:"1106px"}} /> */}
                        <h4>خيارات استبدال المكافآت تشمل:</h4>
                        <ul>
                            <li>تعويض مالي مباشر (السحب عبر المحفظة الرقمية)</li>
                            <li>الالتحاق بدورات تدريبية (الوصول إلى أكاديمية موسوعة لتنمية المهارات)</li>
                            <li>امتيازات خاصة (خصومات في سوق موسوعة وخدمات الشركاء)</li>
                            <li>شارات الإنجاز والمصداقية (نظام تقييم يظهر مستوى خبرتك وموثوقيتك)</li>                            
                        </ul>
                    </Col>
                    </Row>
                    </Container>
                </View>
                <SpacerVertical height={"1.571em"}/>
                <View className="AboutPageContentWhite">
                    <Container>
                    <Row>
                    <Col className="Content">
                        <h3>نظام شفاف قائم على تقنية البلوكشين</h3>
                        <div style={{height: "4px", backgroundColor: "black", width: "74px", marginBottom: "40px"}}></div>                        
                        {/* <Icon name="iconAboutPageReward"  style={{width:"1106px"}} /> */}
                        <h4>لماذا البلوكشين؟</h4>
                        <ul>
                            <li>مكافآت عادلة غير قابلة للتلاعب</li>
                            <li>تحويلات سريعة وآمنة</li>
                            <li>حفظ حقوق ملكية المحتوى عبر العقود الذكية</li>
                        </ul>
                        <p> كل مقال وكل نقطة مكتسبة يتم تسجيلها عبر بلوكشين بولكادوت (Polkadot)، مما يضمن لمؤلفي المحتوى الاحتفاظ بحقوقهم دائماً.</p>
                    </Col>
                    </Row>
                    </Container>
                </View>
                <SpacerVertical height={"1.571em"}/>
                <View className="AboutPageContentWhite">
                    <Container>
                    <Row>
                    <Col className="Content">
                        <h3>الخطوات القادمة</h3>
                        <div style={{height: "4px", backgroundColor: "black", width: "74px", marginBottom: "40px"}}></div>                        
                        {/* <Icon name="iconAboutPageReward"  style={{width:"1106px"}} /> */}
                        <h4>لماذا البلوكشين؟</h4>
                        <ul>
                            <li>تجربة البرنامج مع مجموعة من المستخدمين (الربع الثاني من 2024)</li>
                            <li>الإطلاق التدريجي للبرنامج (الربع الثالث من 2024)</li>
                            <li>إضافة ميزات جديدة لنظام المكافآت (2025)</li>
                        </ul>
                        <p> انضم الآن إلى مجتمع موسوعة وابدأ في تحقيق الأرباح مقابل معرفتك!</p>
                    </Col>
                    </Row>
                    </Container>
                </View>
            </>}
            </View>
            <SpacerVertical height="5.786em"/>
            <AppFooter />
        </View>
    )
}

